function initTabs() {
	let controls = document.querySelectorAll(".tabs__control input[type=radio]")
	controls.forEach(master => {
		let slave = document.querySelector(`#${master.dataset.target}`)

		updateSlave(master, slave)
		master.addEventListener("change", () => {
			updateSlave(master, slave)
		})
	})
}

function updateSlave(master, slave) {
	if (master.checked) {
		slave.checked = true
	}
}

export default initTabs
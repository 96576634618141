import Cleave from "cleave.js"
import 'cleave.js/dist/addons/cleave-phone.ru'
import "./_fslightbox"
import initSliders from "../../modules/splide/script"
import initTabs from "../../modules/tabs/script"
// import { Popup } from "./_Popup"
import Spoiler from "./_Spoiler"
import Swiper from './swiper-bundle.min'
import GLightbox from "glightbox";

document.addEventListener('DOMContentLoaded', function () {
	initTabs()
	initSliders()
	initPopups()
	initPhoneMask()
	initMiniBannerTimer()
	initCatalogHoverSwitch()
	initFsLightboxCustom()
	initTogglers()
	scrollbarWidthToCSS()
	initSpoilers()
	initSlidersSwap()
	initScrollers()
	initBadgeHide()
	initSwiperQNA()
	initG()
	lightboxWrapper()
	mobileSpoilersClose()
	// splideArrows()
})

function initG() {
    const lightbox = GLightbox();
}

function initSwiperQNA() {
	// var swiper = new Swiper(".qna-swiper", {
  
    // });
}

function initSpoilers() {
	window.spoilers = []
	document.querySelectorAll('.spoiler')
		.forEach(spoiler => {
			window.spoilers.push(
				new Spoiler(spoiler))
		})
}

window.addEventListener("load", function () {
	headerHeightToCSS()
	// calcTopForSticky()
})

function scrollbarWidthToCSS() {
	document.querySelector(':root').style.setProperty('--scrollbar-width', `${window.innerWidth - document.documentElement.getBoundingClientRect().width}px`)
}

function initTogglers() {
	/**
	 *
	 * @param {Array<HTMLElement>} elements
	 */
	function setState(...elements) {
		elements.forEach(element => {
			element.toggleAttribute("data-just-toggle-is_active")
		})
	}
	document.querySelectorAll("[data-just-toggle-group]").forEach(group => {
		group.querySelectorAll("[]").forEach(master => {
			master.addEventListener("click", () => {
				setState(group, master)
				group.querySelectorAll("[data-just-toggle-slave]").forEach(slave => {
					setState(slave)
				})
			})
		})
	})
}

function initFsLightboxCustom() {
	document.querySelectorAll('[data-fslightbox-custom]').forEach(opener => {
		opener.addEventListener("click", (event) => {
			event.preventDefault()
			const lightbox = new FsLightbox()
			lightbox.props.sources = ['/src/assets/static/img/sample/statistic1.png']
			lightbox.open()
		})
	})
}

function initCatalogHoverSwitch() {
	let container = document.querySelector(".header__courses-popup__inner")
	let navs = container.querySelectorAll(".header__courses-popup__nav__item")
	let catalogs = container.querySelectorAll(".header__courses-popup__catalog")

	navs.forEach((nav, index) => {
		nav.addEventListener("mouseover", (event) => {
			navs.forEach((nav) => {
				nav.classList.remove("is-active")
			})

			catalogs.forEach((catalog) => {
				catalog.classList.remove("is-active")
			})

			nav.classList.add("is-active")
			catalogs[index]?.classList.add("is-active")
		})
	})
}

function initMiniBannerTimer() {
	document.querySelectorAll(".mini-banner__timer").forEach(timer => {

		let hours = timer.querySelector(`[data-time="hours"]`)
		let minutes = timer.querySelector(`[data-time="minutes"]`)
		let seconds = timer.querySelector(`[data-time="seconds"]`)
		let endtime = new Date(+timer.dataset.endtime)
		let now = new Date()

		const diffTime = Math.abs(endtime - now)
		// let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
		let diffHours = Math.floor(diffTime / (1000 * 60 * 60))
		let diffMinutes = Math.floor(diffTime / (1000 * 60)) % 60
		let diffSeconds = Math.floor(diffTime / (1000)) % 60 % 60

		minutes.innerHTML = diffMinutes
		hours.innerHTML = diffHours
		seconds.innerHTML = diffSeconds

		setInterval(() => {
			if (diffSeconds == 0) {
				diffMinutes--
				diffSeconds = 60
			} else {
				diffSeconds--
			}

			if (diffMinutes == 0) {
				diffHours--
				diffMinutes = 60
			}

			hours.innerHTML = diffHours
			minutes.innerHTML = diffMinutes
			seconds.innerHTML = diffSeconds
		}, 1000)
	})
}

function initPhoneMask() {
	document.querySelectorAll('input[type=tel]')
		.forEach(input => {
			new Cleave(input, {
				phone: true,
				phoneRegionCode: "RU",
				delimiter: "-",
				prefix: "+7",
				noImmediatePrefix: true
			})
		})
}

function headerHeightToCSS() {
	document.querySelector(':root').style.setProperty('--header-height', `${document.querySelector('header').getBoundingClientRect().height}px`)
}

function calcTopForSticky() {
	document.querySelectorAll('[data-sticky]')
		.forEach(sticky => {
			let stickyOffset = sticky.getBoundingClientRect()[sticky.dataset.sticky]
			let minimumOffset = parseInt(document.querySelector(':root')
				.style.getPropertyValue('--header-height')) + 24

			if (minimumOffset && stickyOffset < minimumOffset) stickyOffset = minimumOffset

			sticky.style.setProperty(sticky.dataset.sticky, `${stickyOffset}px`)
		})
}

function toggleNoscrollBody(action) {

	function disable() {
		document.body.style.setProperty("--scroll-position", `${window.pageYOffset}px`)
		document.body.style.setProperty("--scrollbar-width", `${window.innerWidth - document.documentElement.clientWidth}px`)
		document.body.classList.add('noscroll')
	}

	function enable() {
		document.body.classList.remove('noscroll')
		window.scrollTo({
			top: document.body.style.getPropertyValue('--scroll-position')
				.replace('px', ""),
			left: 0,
			behavior: "instant"
		})
	}

	function toggle() {
		if (document.body.classList.contains('noscroll')) {
			enable()
		} else {
			disable()
		}
	}

	if (typeof action !== "undefined") {
		if (action) {
			disable()
		} else {
			enable()
		}
	} else {
		toggle()
	}

}

function ifClickInside(event, targets) {
	// console.log(event.composedPath())

	targets.reduce(function (accumulator, currentValue, index, array) {
		// console.log(accumulator, currentValue, event.composedPath().includes(currentValue))

		return accumulator + event.composedPath().includes(currentValue)
	}, false)

	return targets.reduce(function (accumulator, currentValue, index, array) {
		return accumulator + event.composedPath().includes(currentValue)
	}, false)
}

function removeNoScroll() {
	if (document.body.classList.contains('noscroll')) {
		document.body.classList.remove('noscroll');
		window.scrollTo({
			top: document.body.style.getPropertyValue('--scroll-position').replace('px', ""),
			left: 0,
			behavior: "instant"
		});
	}

}

function addNoScroll() {
	if (!document.body.classList.contains('noscroll')){
		document.body.style.setProperty("--scroll-position", `${window.pageYOffset}px`);
		document.body.style.setProperty("--scrollbar-width", `${window.innerWidth - document.documentElement.clientWidth}px`);
		document.body.classList.add('noscroll');
	}

}

function initPopups() {
	// document.querySelectorAll("[data-popup]").forEach(function (popup) {
	// 	new Popup(popup).bindGlobalControls()
	// })

	function initPopup(popup) {
		let openedClass = "show"
		let inner = popup.querySelector(".popup__inner")
		let controllers = Array.from(document.querySelectorAll(`[data-popup-target="${popup.id}"]`))
		let openers = controllers.filter(controller => controller.dataset.popupControl == "open")
		let togglers = controllers.filter(controller => controller.dataset.popupControl == "toggle")
		let closers = [...controllers.filter(controller => controller.dataset.popupControl == "close"), ...popup.querySelectorAll(".popup__selfcloser")]

							// let controllers = Array.from(document.querySelectorAll('[data-popup-target]'))
							// let openers = controllers.filter(controller => controller.dataset.popupControl == "open")
							// let togglers = controllers.filter(controller => controller.dataset.popupControl == "toggle")
							// let closers = [...controllers.filter(controller => controller.dataset.popupControl == "close"), ...document.querySelectorAll(".popup__selfcloser")]
							// let popups = document.querySelectorAll('[data-popup]')
							// // let closers1 = document.querySelectorAll(".popup__selfcloser", "[]")
							// console.log(closers);
							// // console.log(closers1);

							// openers.forEach(opener => opener.addEventListener('click', (e) => {
							// 	popups.forEach(x => x.classList.remove("show"))
							// 	e.stopPropagation()
							// 	let popup = document.getElementById(opener.dataset.popupTarget)

							// 	opener.classList.add("popup-controller--active")
							// 	popup.classList.add("show")

							// 	ifClickInside(popup)
							// }))

							// closers.forEach(closer => closer.addEventListener('click', (e) => {
							// 	e.stopPropagation()
							// 	let popup = closer.closest('[data-popup]')

							// 	popup.classList.remove("show")
							// }))

							// togglers.forEach(toggler => toggler.addEventListener('click', (e) => {
							// 	e.stopPropagation()
							// 	let popup = document.getElementById(toggler.dataset.popupTarget)

							// 	popup.classList.toggle("show")

							// 	ifClickInside(popup)
							// }))

							// function ifClickInside(popup) {
								
							// 	if (popup.classList.contains("show")) {
							// 		document.addEventListener('click', (e) => {
										
							// 			const click = e.composedPath().includes(popup)
							// 			const button = e.composedPath().includes(opener)

							// 			if (!click) {
							// 				popups.forEach(x => x.classList.remove("show"))
							// 			}
							// 		})
							// 	}
							// }
		

		function closePopup(event) {
			popup.classList.remove(openedClass)
			removeNoScroll();
			console.log('закрыл');
		}

		function openPopup(event) {
			popup.classList.add(openedClass)
			event?.stopPropagation()
			addNoScroll();
			console.log('открыл');
		}

		function togglePopup(event) {
			popup.classList.toggle(openedClass)
			toggleNoscrollBody()
		}

		window[`closePopup_${popup.id}`] = closePopup
		window[`openPopup_${popup.id}`] = openPopup
		window[`togglePopup_${popup.id}`] = togglePopup

		openers.forEach(function (opener) {
			opener.addEventListener('click', function () {
				openPopup()
				opener.classList.add("popup-controller--active")				
			})
		})

		togglers.forEach(function (toggler) {
			toggler.addEventListener('click', function () {
				togglePopup()
				toggler.classList.toggle("popup-controller--active")
			})
		})

		closers.forEach(function (closer) {
			closer.addEventListener('click', function () {
				closePopup()
			})
		})

		document.addEventListener('click', function (event) {
			if (!ifClickInside(event, [inner, ...openers, ...togglers, ...closers]) && popup.classList.contains(openedClass) && event.target.dataset.popupControl != "open") {
				closePopup(event)

			}
		})

	}
	document.querySelectorAll("[data-popup]").forEach(initPopup)
	window.initPopup = initPopup

					// document.querySelectorAll("[data-popup-target]").forEach(x => x.addEventListener('click', () => {
					// 	document.getElementById(x.dataset.popupTarget).classList.add('show')
					// }))
}

/* 
function scrollTo(element) {
	window.scroll({
		left: 0,
		top: element.offsetTop
	})
}

const setListener = (element, type, handler) => {
	if(!element) {
		return;
	}
	element.addEventListener(type, handler);
	return () => {
		element.removeEventListener(type, handler);
	}
}

const webinarBtn = document.getElementsByClassName("webinarBtn")[0];
const webinarSkroll = document.querySelector('#webinarChoise');

setListener(webinarBtn, "click", () => {
	scrollTo(webinarSkroll);
})

const staffWelcomeBtn = document.querySelector('.staffWelcomeBtn');
const staffSkroll = document.querySelector('.staffSkroll');

setListener(staffWelcomeBtn, "click", () => {
	scrollTo(staffSkroll);
})

const loyaltyAnchor_1 = document.querySelector('.loyaltyAnchorFirst');
const loyaltyAnchor_2 = document.querySelector('.loyaltyAnchorSecond');
const loyaltySkroll_1 = document.querySelector('.loyaltySkrollFirst');
const loyaltySkroll_2 = document.querySelector('.loyaltySkrollSecond');

setListener(loyaltyAnchor_1, "click", () => {
	scrollTo(loyaltySkroll_1);
})

setListener(loyaltyAnchor_2, "click", () => {
	scrollTo(loyaltySkroll_2);
})

const showMoreBtn = document.querySelector(".showMoreBtn");
const otherCoursesLength = document.querySelectorAll(".course-card").length;
let courseItems = 5;

setListener(showMoreBtn, 'click', function() {
	courseItems += 10;
	const arrayCourses = Array.from(document.querySelector(".course-cards").children);
	const visCourseItems = arrayCourses.slice(0, courseItems);

	visCourseItems.forEach(el => el.classList.add('is-visible'));

	if (visCourseItems.length == otherCoursesLength) {
		showMoreBtn.style.display = 'none';
	}
})

const saleBtn = document.querySelector('.header-banner__content__promo__icon');
const saleInfo = document.querySelector('.header-banner__content__promo__text');

setListener(saleBtn, 'click', function() {
	saleInfo.classList.toggle('is-visible');
})

setListener(saleInfo, 'click', function() {
	saleInfo.classList.toggle('is-visible');
})
 */
function initSlidersSwap() {
	if (!document.querySelector(".splide--type-5") && !document.querySelector(".splide--type-5_1")) {
		return;
	} else {
		const splide_array_right = Array.from(document.querySelector(".splide--type-5 .splide .splide__track .splide__list").children);
		const splide_array_left = Array.from(document.querySelector(".splide--type-5_1 .splide .splide__track .splide__list").children);

		/* let arrows = document.querySelectorAll(".splide--type-5 .splide .splide__arrows"); */
		let arrow_prev = document.querySelector(".splide--type-5 .splide .splide__arrows .splide__arrow--prev");
		let arrow_next = document.querySelector(".splide--type-5 .splide .splide__arrows .splide__arrow--next");

		arrow_prev.addEventListener('click', prevActiveText)
		
		function prevActiveText() {

			let activeLeftItem = splide_array_left.find(function (item) {
				return item.classList.contains('active');
			})

			let activeRightIndex = splide_array_right.findIndex(function (item) {
				return item.classList.contains('is-active');
			})

			activeLeftItem.classList.remove('active');

			splide_array_left[activeRightIndex-1].classList.add('active');

			/* console.log(activeLeftItem, activeRightIndex); */
		}

		arrow_next.addEventListener('click', nextActiveText)
		
		function nextActiveText() {

			let activeLeftItem = splide_array_left.find(function (item) {
				return item.classList.contains('active');
			})

			let activeRightIndex = splide_array_right.findIndex(function (item) {
				return item.classList.contains('is-active');
			})

			activeLeftItem.classList.remove('active');

			splide_array_left[activeRightIndex+1].classList.add('active');
		}

		let poligon = document.querySelector('.learning-steps');

		poligon.addEventListener('touchstart', handleTouchStart, false);
		poligon.addEventListener('touchmove', handleTouchMove, false);

		let x1 = null;

		function getTouches(event) {
			return event.touches;
			}

		function handleTouchStart(event) {
			const firstTouch = getTouches(event)[0];
			x1 = firstTouch.clientX;
			};

		function handleTouchMove(event) {
			if (!x1) {
				return false
			}

			let x2 = event.touches[0].clientX;
			
			let xDiff = x2 - x1;
		
			if (xDiff > 0) {
				if (arrow_prev.disabled == false) {prevActiveText()}
			}
			else {
				if (arrow_next.disabled == false) {nextActiveText()}
			};
			
			x1 = null;
		}
	}
}

	/* arrows.forEach(slide_swap);

	function slide_swap (item) {
		item.addEventListener('click', () => {

			let activeLeftItem = splide_array_left.find(function (item) {
				return item.classList.contains('active');
			})

			let activeRightIndex = splide_array_right.findIndex(function (item) {
				return item.classList.contains('is-active');
			})

			activeLeftItem.classList.remove('active');

			splide_array_left[activeRightIndex].classList.add('active');

			console.log(activeLeftItem, activeRightIndex);
		})
	} */

function initScrollers() {

	function scrollTo(element) {
		window.scroll({
			left: 0,
			top: element.offsetTop
		})
	}

	if (document.querySelector(".variantsBtn")) {
		const variantsBtn = document.querySelector(".variantsBtn");
		const variantsScroll = document.querySelector('.variantsScroll');

		variantsBtn.addEventListener('click', () => {
			if (!document.querySelector('.simple-sticky')) {
				window.scroll({
					left: 0,
					top: variantsScroll.offsetTop
				})
			} else {
				let head = document.querySelector('.simple-sticky');
				let doc = document.documentElement;
				let docStyles = window.getComputedStyle(doc);
				let h = head.offsetHeight;

				doc.style.setProperty('--head-padding', `${h}px`);

				window.scroll({
					left: 0,
					top: variantsScroll.offsetTop - h
				})
			}
		})	

		const showMoreBtn = document.querySelector(".showMoreBtn");
		const otherCoursesLength = document.querySelectorAll(".course-card").length;
		let courseItems = 5;
		
		showMoreBtn.addEventListener('click', () => {
			courseItems += 10;
			const arrayCourses = Array.from(document.querySelector(".course-cards").children);
			const visCourseItems = arrayCourses.slice(0, courseItems);

			visCourseItems.forEach(el => el.classList.add('is-visible'));

			if (visCourseItems.length == otherCoursesLength) {
				showMoreBtn.style.display = 'none';
			}
		})
	} else if (document.querySelector(".webinarBtn")) {
		const webinarBtn = document.querySelector(".webinarBtn");
		const webinarSkroll = document.querySelector('#webinarChoise');

		webinarBtn.addEventListener('click', () => {
			scrollTo(webinarSkroll);
		})
	} else if (document.querySelector('.staffWelcomeBtn')) {
		const staffWelcomeBtn = document.querySelector('.staffWelcomeBtn');
		const staffSkroll = document.querySelector('.staffSkroll');
		
		staffWelcomeBtn.addEventListener('click', () => {
			scrollTo(staffSkroll);
		})
	} else if (document.querySelector('.loyaltyAnchorFirst')) {
		const loyaltyAnchor_1 = document.querySelector('.loyaltyAnchorFirst');
		const loyaltyAnchor_2 = document.querySelector('.loyaltyAnchorSecond');
		const loyaltySkroll_1 = document.querySelector('.loyaltySkrollFirst');
		const loyaltySkroll_2 = document.querySelector('.loyaltySkrollSecond');

		loyaltyAnchor_1.addEventListener('click', () => {
			scrollTo(loyaltySkroll_1);
		})

		loyaltyAnchor_2.addEventListener('click', () => {
			scrollTo(loyaltySkroll_2);
		})
	} else {
		return;
	}
}

/* 
setListener(showMoreBtn, 'click', function() {
	courseItems += 10;
	const arrayCourses = Array.from(document.querySelector(".course-cards").children);
	const visCourseItems = arrayCourses.slice(0, courseItems);

	visCourseItems.forEach(el => el.classList.add('is-visible'));

	if (visCourseItems.length == otherCoursesLength) {
		showMoreBtn.style.display = 'none';
	}
}) */

function initBadgeHide() {
	if (document.querySelector('.badge--arrow')) {
		const badgeSpoiler = document.querySelector('.badge--arrow');
		const hiddenBadges = document.querySelectorAll('.badge--not-visible');
		
		badgeSpoiler.addEventListener('click', () => {
			hiddenBadges.forEach(function(item) {
				item.classList.toggle('badge--not-visible');
			})

			badgeSpoiler.classList.toggle('active');
		})
	}
}

function lightboxWrapper() {
	let links = document.querySelectorAll('.lightbox-wrapper')

	links.forEach(x => x.addEventListener('click', () => {
		x.querySelector('.glightbox.hidden').click()
	}))
}

function mobileSpoilersClose() {
	let filter = document.querySelector('.catalog__filters')

	if (filter) {
		let spoilers = document.querySelectorAll('.spoiler')
		let spoilerTogglers = document.querySelectorAll('.spoiler__toggler')
		let spoilerContents = document.querySelectorAll('.spoiler__content')

		if (window.screen.width <= 1260 && document.querySelector('.spoiler.is-active')) {
			spoilers.forEach(x => x.classList.remove('is-active'))
			spoilerTogglers.forEach(x => x.classList.remove('is-active'))
			spoilerContents.forEach(x => x.classList.remove('is-active'))
		}

		window.addEventListener('resize', () => {
			if (window.screen.width <= 1260 && document.querySelector('.spoiler.is-active')) {
				spoilers.forEach(x => x.classList.remove('is-active'))
				spoilerTogglers.forEach(x => x.classList.remove('is-active'))
				spoilerContents.forEach(x => x.classList.remove('is-active'))
			} else if (window.screen.width > 1260) {
				spoilers.forEach(x => x.classList.add('is-active'))
				spoilerTogglers.forEach(x => x.classList.add('is-active'))
				spoilerContents.forEach(x => x.classList.add('is-active'))
			}
		})
	}
}

// function splideArrows() {
// 	let splide = document.querySelector('.splide')

// 	if (splide) {
// 		let splides = document.querySelectorAll('.splide')

// 		splides.forEach(x => {
// 			let splideArrows = x.querySelector('.splide__arrows')
// 			let list = x.querySelector('.splide__list')
// 			let items = list.querySelectorAll('li')

// 			console.log(items);

// 			if (items <= 3) {
// 				splideArrows.classList.add('hidden')
// 			} else {
// 				splideArrows.classList.remove('hidden')
// 			}
// 		})
// 	}
// }
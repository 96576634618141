
class Spoiler {
	/**
	 *
	 * @param {Element} target
	 */
	constructor(target) {
		this.spoiler = target
		this.togglers = [target.querySelector('.spoiler__toggler')]
		this.content = target.querySelector('.spoiler__content')
		this.activeClass = "is-active"
		this.nestedSpoilers = target.querySelectorAll(".spoiler")
		this.duration = +target.dataset?.spoilerDuration || 300


		if (target.id) {
			this.togglers.push(document.querySelector(`.spoiler__toggler[data-spoiler-target='${target.id}']`))
		}
		this.initClick()
		this.injectDuration()

	}

	slideToggle() {
		this.content.style.setProperty("max-height", `${this.content.scrollHeight}px`)
		setTimeout(() => {
			this.content.style.removeProperty("max-height")
		}, this.duration)

	}

	injectDuration() {
		this.spoiler.style.setProperty("--transition-duration", `${this.duration / 1000}s`)
	}

	initClick() {
		this.togglers.forEach(toggler => {
			toggler.addEventListener('click', () => {
				this.slideToggle()
				this.spoiler.classList.toggle(this.activeClass)
				this.content.classList.toggle(this.activeClass)
				toggler.classList.toggle(this.activeClass)
			})
		})
		if (this.spoiler.dataset?.spoilerDefaultOpened) {
			this.slideToggle()
			this.spoiler.querySelector('.spoiler__toggler').click()
		}
	}
}

export default Spoiler

import Splide, { FADE, LOOP } from "@splidejs/splide"
/* 
const splideArr = [document.getElementById("splide05-slide01"), document.getElementById("splide05-slide02"), document.getElementById("splide05-slide03"), document.getElementById("splide05-slide04"), document.getElementById("splide05-slide05")];
const leftArr = [document.getElementById("splide06-slide01"), document.getElementById("splide06-slide02"), document.getElementById("splide06-slide03"), document.getElementById("splide06-slide04"), document.getElementById("splide06-slide05")];

 */
function initSliders() {
	Splide.defaults = {
		mediaQuery: "min",
		classes: {
			arrows: 'splide__arrows',
			arrow: 'splide__arrow',
			prev: 'splide__arrow--prev splide__arrow--native',
			next: 'splide__arrow--next splide__arrow--native',
			pagination: 'splide__pagination',
			page: 'splide__pagination__page',
		},

	}

	initType1()
	initType2()
	initType3()
	initType4()
	initType5()
	initType6()
	initType7()
	initType8()
	initType9()
	initType10()
	initType11()
	initType12()
	// initType13()

	/**
	 *
	 * @param {Splide} splide
	 * @param {Element} counter
	 */
	function bindCounter(splide, counter) {
		splide.on("mounted", function () {
			counter.setAttribute("data-slide-total", splide.Components.Slides.getLength())
			counter.setAttribute("data-slide-current", splide.index + 1)
		}).on("moved", function () {
			counter.setAttribute("data-slide-current", splide.index + 1)
		})
	}

	function bindArrows(splide, container) {
		let arrows_prev = container.querySelectorAll(".splide__arrow--prev:not(.splide__arrow--native)")
		let arrows_next = container.querySelectorAll(".splide__arrow--next:not(.splide__arrow--native)")

		function setArrowsState(current_index = 0) {
			if (current_index == 0) {
				arrows_prev.forEach(arrow => {
					arrow.toggleAttribute("disabled", true)
				})
			} else {
				arrows_prev.forEach(arrow => {
					arrow.toggleAttribute("disabled", false)
				})
			}

			if (current_index == splide.Components.Slides.getLength() - 1) {
				arrows_next.forEach(arrow => {
					arrow.toggleAttribute("disabled", true)
				})
			} else {
				arrows_next.forEach(arrow => {
					arrow.toggleAttribute("disabled", false)
				})
			}
		}

		splide.on("moved", setArrowsState)
		splide.on("mounted", setArrowsState)

		arrows_prev.forEach(arrow => {
			arrow.addEventListener("click", function () {
				splide.go('<')
			})
		})

		arrows_next.forEach(arrow => {
			arrow.addEventListener("click", function () {
				splide.go('>')
			})
		})
	}

	function initType1() {
		document.querySelectorAll(".splide--type-1").forEach(container => {
			let splide = new Splide(container.querySelector(".splide"), {
				arrows: false,
				autoWidth: true,
				gap: 16,
				pagination: false,
				breakpoints: {
					768: {
						perPage: 3,
						autoWidth: false,
						arrows: true,
						gap: 24,
					}
				}
			})

			bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})

	}

	function initType2() {
		document.querySelectorAll(".splide--type-2").forEach(container => {
			let splide = new Splide(container.querySelector(".splide"), {
				perPage: 1,
				perMove: 1,
				gap: 24,
				pagination: false,
				arrows: false,
				breakpoints: {
					768: {
						perPage: 2,
						arrows: true,
					}
				}
			})

			bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})
	}

	function initType3() {
		document.querySelectorAll(".splide--type-3").forEach(container => {
			let splide = new Splide(container.querySelector(".splide"), {
				perPage: 1,
				perMove: 1,
				gap: 24,
				pagination: false,
				arrows: false,
				breakpoints: {
					768: {
						perPage: 3,
						arrows: true,
					},
					1400: {
						perPage: 4,
						arrows: true,
					}
				}
			})

			bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})
	}

	function initType4() {
		document.querySelectorAll(".splide--type-4").forEach(container => {
			let splide = new Splide(container.querySelector(".splide"), {
				perPage: 1,
				perMove: 1,
				gap: 16,
				pagination: false,
				arrows: false,
				breakpoints: {
					768: {
						arrows: true,
						gap: 24,
						perPage: 2,
					},
					1400: {
						autoWidth: false
					}
				}
			})

			bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})

	}

	function initType5() {
		document.querySelectorAll(".splide-group--type-5").forEach(group => {
			let master = group.querySelector(".splide--type-5 .splide")
			let slave = group.querySelector(".splide--type-5_1 .splide")
			if (!(master && slave)) {
				return
			}
			let splideMaster = new Splide(master, {
				perPage: 1,
				perMove: 1,
				pagination: false,
				type: FADE
			})

			let splideSlave = new Splide(slave, {
				arrows: false,
				perPage: 1,
				perMove: 1,
				pagination: false,
				type: FADE,
				isNavigation: true,
				breakpoints: {
					768: {
						destroy: "completely",
					}
				}
			})

			bindArrows(splideMaster, group)
			splideMaster.sync(splideSlave)
			splideMaster.mount()
			splideSlave.mount()
/* 			console.log(splideMaster) */

		})
	}

	function initType6() {
		document.querySelectorAll(".splide--type-6").forEach(container => {
			let splide = new Splide(container.querySelector(".splide"), {
				type: LOOP,
				arrows: false,
				autoWidth: true,
				preloadPages: 1,
				gap: 16,
				pagination: false,
				speed: 2000,
				interval: 2000,
				autoplay: true,
				easing: "linear",
				// clones: 20,
				breakpoints: {
					768: {
						gap: 24,
					}
				}
			})

			// bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})
	}

	function initType7() {
		document.querySelectorAll(".splide--type-7").forEach(container => {
			let splide = new Splide(container.querySelector(".splide"), {
				perPage: 1,
				perMove: 1,
				gap: 24,
				pagination: false,
				arrows: false,
				breakpoints: {
					768: {
						perPage: 2,
						arrows: true
					},
					1400: {
						perPage: 3,
					}
				}
			})

			bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})


	}
	function initType8() {
		let cards = document.querySelectorAll(".how-is-card")
		cards.forEach(card => {
			let slider = card.querySelector(".splide")
			if (slider) {
				let splide = new Splide(slider, {
					perPage: 1,
					perMove: 1,
					pagination: false,
					type: FADE,
					arrows: false
				})

				bindCounter(splide, card.querySelector(".counter"))
				bindArrows(splide, card)

				splide.mount()
			}
		})
	}
	function initType9() {
		document.querySelectorAll(".splide--type-9").forEach(container => {

			let splide = new Splide(container.querySelector(".splide"), {
				perPage: 1,
				perMove: 1,
				gap: 4,
				pagination: false,
				arrows: false,
				breakpoints: {
					768: {
						destroy: "completely"
					}
				}
			})

			bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})
	}

	function initType10() {
		document.querySelectorAll(".splide--type-10").forEach(container => {
			let splide = new Splide(container.querySelector(".splide"), {
				arrows: false,
				perMove: 3,
				gap: 16,
				pagination: false,
				perPage: 1,
				breakpoints: {
					768: {
						perPage: 3,
						autoWidth: false,
						arrows: true,
						gap: 24,
					}
				}
			})

			bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})
	}

	function initType11() {
		document.querySelectorAll(".splide--type-11").forEach(container => {
			let splide = new Splide(container.querySelector(".splide"), {
				arrows: false,
				perMove: 1,
				gap: 16,
				pagination: false,
				autoWidth: true,
				breakpoints: {
					768: {
						perPage: 3,
						autoWidth: false,
						arrows: true,
						gap: 24,
						autoWidth: false
					}
				}
			})

			bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})
	}

	function initType12() {
		document.querySelectorAll(".splide--type-12").forEach(container => {
			let splide = new Splide(container.querySelector(".splide"), {
				arrows: false,
				perMove: 1,
				autoWidth: true,
				pagination: false,
				breakpoints: {
					1260: {
						destroy: "completely"
					}
				}
			})

			bindCounter(splide, container.querySelector(".counter"))

			splide.mount()
		})
	}

	// function initType13() {
	// 	document.querySelectorAll(".splide--type-13").forEach(container => {
	// 		let splide = new Splide(container.querySelector(".splide"), {
	// 			perPage: 1,
	// 			perMove: 1,
	// 			gap: 24,
	// 			pagination: false,
	// 			arrows: false,
				
	// 			breakpoints: {
	// 				868: {
	// 					perPage: 1,
	// 					arrows: false,
	// 					pagination: true,
	// 					classes: {
	// 						pagination: 'splide__pagination qna-pagination',
	// 						page      : 'splide__pagination__page qna-pagination-page',
	// 					  },
	// 				}
	// 			}
	// 		})

	// 		// splide.on( 'pagination:mounted', function ( data ) {
	// 		// 	// You can add your class to the UL element
	// 		// 	data.list.classList.add( 'splide__pagination--custom' );
			  
	// 		// 	// `items` contains all dot items
	// 		// 	data.items.forEach( function ( item ) {
	// 		// 	  item.button.textContent = String( item.page + 1 );
	// 		// 	} );
	// 		//   } );
	// 		splide.mount()
	// 	})

	// 	let buttons = document.querySelectorAll('.qna-pagination li button');
	// 	let buttonsArray = Array.from(buttons)
	// 	buttonsArray[0].innerHTML = 'Об институте';
	// 	buttonsArray[1].innerHTML = 'По программам';
	// 	buttonsArray[2].innerHTML = 'По поступлению';
	// 	buttonsArray[3].innerHTML = 'По обучению';
	// 	buttonsArray[4].innerHTML = 'По оплате';
	// 	buttonsArray[5].innerHTML = 'По документам';
	// 	buttonsArray[6].innerHTML = 'По вебинарам';
	// }
}

export default initSliders


